import React from 'react'
import { Moment } from 'moment'
import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { DMY_CASUAL_SHORT_FORMAT } from 'constants/dateFormats'
import ClickableLink from 'components/Common/Clickable/ClickableLink'
import AspectRatio from 'components/utils/AspectRatio'
import styled from 'styled-components'
import TextButton from 'components/Luxkit/Button/TextButton'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Image from 'components/Common/Image/Image'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'

const Root = styled(Group)`
  width: 75vw;
  position: relative;

  ${mediaQueryUp.tablet} {
    width: 40vw;
  }

  ${mediaQueryUp.desktop} {
    width: unset;
  }
`

const NestedLink = styled(ClickableLink)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;

`
// Base properties shared across all props
interface BaseProps {
  title: string;
  date?: Moment;
  description: string;
  lineClamp?: number;
}

// URL or Slug requirement (Mutually Exclusive)
type SlugOrUrl =
  | { slug: string; url?: never }
  | { slug?: never; url: string };

// Image requirement (Mutually Exclusive)
type ImageOrImageId =
  | { imageId: string; image?: never }
  | { imageId?: never; image: App.Image };

// Final Props Type
type Props = BaseProps & SlugOrUrl & ImageOrImageId;

function BlogCard(props: Props) {
  const { lineClamp = 3, title, date, description, slug, imageId, image, url } = props

  return (
    <Root direction="vertical" verticalAlign="space-between" gap={12}>
      <VerticalSpacer gap={12} >
        <AspectRatio ratio="16:9">
          <Image alt={title} aspectRatio="16:9" id={imageId} image={image} fit="center" dpr={2} width="375"/>
        </AspectRatio>
        <VerticalSpacer gap={4}>
          <Heading variant="heading4" lineClamp={2}>{title}</Heading>
          {date && <BodyText variant="medium">{date.format(DMY_CASUAL_SHORT_FORMAT)}</BodyText>}
        </VerticalSpacer>
        <BodyText variant="medium" lineClamp={lineClamp}>{description}</BodyText>
      </VerticalSpacer>
      <CSSBreakpoint min="tablet">
        <Group direction="horizontal" horizontalAlign="end">
          <TextButton kind="primary">
            View article
          </TextButton>
        </Group>
      </CSSBreakpoint>
      <NestedLink target="_blank" href={url || `https://luxuryescapes.com/inspiration/${slug}`} />
    </Root>
  )
}

export default React.memo(BlogCard)
