import React, { useCallback, useRef } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import Group from 'components/utils/Group'
import * as Analytics from 'analytics/analytics'
import Heading from 'components/Luxkit/Typography/Heading'
import { promotionViewEvent } from 'analytics/snowplow/events'
import CardCarousel from 'components/Luxkit/Carousel/CardCarousel'
import LayoutContainer from 'components/Common/LayoutContainer/LayoutContainer'
import EuropeInspirationBanner from './EuropeInspirationBanner'

const BannerSectionContainer = styled(LayoutContainer)`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(20)};
`

const EUROPE_INSPIRATION_BANNERS: Array<App.EuropeInspirationBanner> = [
  {
    name: 'All-inclusive bliss',
    imageId: '0b4uiv1ajsa92le95lqc9e',
    to: '/search?destinationName=Anywhere&destinationId=le_d41d8cd98f00b204e9800998ecf8427e&adults=2&children=none&flexibleWithDate=true&pages=2&holidayTypes=All-inclusive',
  },
  {
    name: 'Europe\'s most enchanting cities',
    imageId: 'laaoinyb4mq6zexi3ye',
    to: '/search?destinationName=Europe&destinationId=le_12767faf1bf8cafc7594430586c558c2&adults=2&children=none&flexibleWithDate=true&pages=2&holidayTypes=City+Break',
  },
  {
    name: 'Worlds dreamiest pools',
    imageId: 'yftbvkb1ytizdvfir7bu',
    to: '/search?destinationName=Anywhere&destinationId=le_d41d8cd98f00b204e9800998ecf8427e&adults=2&children=none&flexibleWithDate=true&pages=4&amenities=Private+pool',
  },
]

function EuropeInspirationBannerSection() {
  const loadedBanners = useRef(new Set<string>())

  const onBannerLoad = useCallback((banner: App.EuropeInspirationBanner) => {
    if (loadedBanners.current.has(banner.name)) return

    loadedBanners.current.add(banner.name)
    Analytics.trackEvent(promotionViewEvent({ promotionContext: { promotionName: banner.name, promotionType: 'banner' } }))
  }, [])

  return (
    <BannerSectionContainer>
      <Group direction="vertical" gap={20}>
        <Heading variant="heading2"><>Find your next <i>dream escape</i></></Heading>
        <CardCarousel pageSize={3}>
          {EUROPE_INSPIRATION_BANNERS.map(banner => (
            <EuropeInspirationBanner key={banner.imageId} banner={banner} onLoad={onBannerLoad} />
          ))}
        </CardCarousel>
      </Group>
    </BannerSectionContainer>
  )
}

export default EuropeInspirationBannerSection
