import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import TrendingDestinations from '../../components/TrendingDestinations/TrendingDestinations'
import Search from '../../components/Search/Search'
import Pane from 'components/Common/Pane'
import { selectLoggedIn } from 'selectors/accountSelectors'
import HeroOfferCarouselSection from '../../components/OfferCarousels/HeroOfferCarouselSection'
import HotelOfferCarousel from '../../components/OfferCarousels/HotelOfferCarousel'
import TourOfferCarousel from '../../components/OfferCarousels/TourOfferCarousel'
import ValuePropositionBanner from '../../components/ValuePropositionBanner/ValuePropositionBanner'
import MarketingCarousel from '../../components/MarketingCarousel/MarketingCarousel'
import BlogCarousel from '../../components/Blog/BlogCarousel'
import BrowseOfferTypes from 'home/components/BrowseOfferTypes/BrowseOfferTypes'
import SmartExperienceOfferCarousel from 'home/components/OfferCarousels/ExperienceOfferCarousel/SmartExperienceOfferCarousel'
import GeoContext from 'contexts/geoContext'
import UltraLuxOfferCarousel from 'home/components/OfferCarousels/UltraLuxHotelOfferCarousel'
import config from 'constants/config'
import TopPicksForMeCarousel from 'home/components/OfferCarousels/TopPicksForMeCarousel'
import PromptCardSelector from 'components/Common/StickyPromptCard/PromptCardSelector'
import { OfferListEventsProvider } from 'components/OfferList/OfferListEventsContext'
import useHomepageAnalytics from 'home/pages/HomePage/useHomepageAnalytics'
import { rem } from 'polished'
import styled from 'styled-components'
import { GlobalSearchTrackingProvider } from 'contexts/GlobalSearch/GlobalSearchTracking'
import HomepageFlightDeals from 'home/components/FlightDeals/HomepageFlightDeals'
import AnalyticsPageContext, { AnalyticsPage } from 'contexts/Analytics/analyticsPageContext'
import ErrorBoundary from 'components/Common/ErrorBoundary/ErrorBoundary'
import TrustIndicatorSection from 'home/components/TrustIndicatorSection/TrustIndicatorSection'
import CruiseOfferCarousel from 'home/components/OfferCarousels/CruiseOfferCarousel'
import { isStandaloneCruiseEnabled } from 'selectors/cruiseOfferSelectors'
import FrontPageUSPComponent from 'components/Pages/FrontPage/USP/FrontPageUSPComponent'
import Divider from 'components/Luxkit/Divider'
import LayoutContainer from 'components/Common/LayoutContainer'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Trip from 'home/components/Trip'
import { InView } from 'react-intersection-observer'
import SupportTile from 'components/Common/SupportTile/SupportTile'
import { HOMEPAGE_EUROPE_INSPIRATION_BANNERS_REGIONS, HOMEPAGE_FLIGHT_DEALS_REGIONS, NO_24_7_SUPPORT_REGIONS } from 'constants/config/region'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import useQueryParams from 'hooks/useQueryParams'
import LuxPlusOfferCarousel from 'home/components/OfferCarousels/LuxPlusOfferCarousel'
import { checkCanRedeemLuxPlusBenefits, isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import RequestAgentCallbackModal from 'supportAssistant/components/RequestAgentCallback/Modals/RequestAgentCallbackModal'
import ModalContext from 'contexts/ModalContext'
import { removeSearchParamValue } from 'lib/url/searchUrlUtils'
import { replace } from 'connected-react-router'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useLocation } from 'react-router'
import CompactBookingDetailsUpsellSection from 'checkout/Components/Confirmation/BookingDetailsV2/CompactBookingDetailsUpsellSection'
import { fetchOrders } from 'actions/OrderActions'
import { haveOrdersBeenFetched, sortOrdersByDeparture } from 'lib/order/orderUtils'
import { getUpcomingOrders } from 'selectors/orderSelectors'
import { upsellEnabledForOrder } from 'lib/order/upsellUtils'
import OfferDetailUpsellSection from 'checkout/Components/Confirmation/BookingDetailsV2/OfferDetailUpsellSection'
import Group from 'components/utils/Group'
import EuropeInspirationBannerSection from 'home/components/EuropeInspirationBannerSection/EuropeInspirationBannerSection'
import { REQUEST_SALES_CALLBACK_PATH, REQUEST_SUPPORT_CALLBACK_PATH } from 'supportAssistant/components/RequestAgentCallback/RequestAgentCallbackUtils'
import RecentSearches from 'home/components/RecentSearches/RecentSearches'
import { TERMS_AND_CONDITIONS_ACKNOWLEDGED_PATH } from 'home/constants'
import TermsAndConditionsAcknowledgedModal from 'components/App/TermsAndConditionsModal/TermsAndConditionsAcknowledgedModal'
import { OptimizelyExperiments, OptimizelyFeatureFlags } from 'constants/optimizely'
import useOfferList from 'hooks/Offers/useOfferList'
import { unique, without } from 'lib/array/arrayUtils'
import { getAlternativesOfferListParams, getHomeOfferListParams } from 'home/helpers'
import { getCanTriggerLerePersonalisedHeroCarousel } from 'lib/optimizely/optimizelyUtils'

const HomepageSection = styled(LayoutContainer)`
  padding-top: ${rem(40)};
`

interface MappedProps {
  isLoggedIn: boolean;
  isStandaloneCruiseEnabled: boolean;
  isLuxPlusEnabled: boolean;
  canRedeemLuxPlusBenefits: boolean;
}

function HomePage(props: MappedProps) {
  const {
    isLoggedIn,
    isStandaloneCruiseEnabled,
    isLuxPlusEnabled,
    canRedeemLuxPlusBenefits,
  } = props
  const query = useQueryParams()
  const showSupportCallbacksModal = query.has(REQUEST_SUPPORT_CALLBACK_PATH)
  const showSalesCallbacksModal = query.has(REQUEST_SALES_CALLBACK_PATH)
  const { currentRegionCode, geoStateName } = useContext(GeoContext)
  const ordersFetched = useAppSelector((state) => haveOrdersBeenFetched(state, 'upcoming'))
  const upcomingOrders = useAppSelector((state) => getUpcomingOrders(state))

  const showSupportContainer = !NO_24_7_SUPPORT_REGIONS.includes(currentRegionCode)

  const isNewFlightCarouselEnabled = !!useOptimizelyExperiment(OptimizelyFeatureFlags.croNewFlightsSaleCarouselEnabled)
  const upsellTilesFeatureToggle = !!useOptimizelyExperiment(OptimizelyFeatureFlags.upsellTilesFeatureToggle)
  const showFlightDeals = HOMEPAGE_FLIGHT_DEALS_REGIONS.includes(currentRegionCode) && isNewFlightCarouselEnabled
  const showEuropeInspirationBanners = HOMEPAGE_EUROPE_INSPIRATION_BANNERS_REGIONS.includes(currentRegionCode)
  const showFrontPageUSP = !isLoggedIn && !showEuropeInspirationBanners
  const termsAndConditionsAcknowledgedCartId = query.get(TERMS_AND_CONDITIONS_ACKNOWLEDGED_PATH)

  const { search } = useLocation()
  const showModal = useContext(ModalContext)
  const dispatch = useAppDispatch()

  const handleRequestAgentCallbackModal = useCallback((callbackType: App.GenesysAgentCallbackType, callbackPath: string) => {
    showModal(<RequestAgentCallbackModal type={callbackType} />)

    const nextSearch = removeSearchParamValue(search, callbackPath, 'true')
    dispatch(replace({ search: nextSearch }))
  }, [dispatch, search, showModal])

  useEffect(() => {
    if (showSalesCallbacksModal) {
      handleRequestAgentCallbackModal('SALES', REQUEST_SALES_CALLBACK_PATH)
    } else if (showSupportCallbacksModal) {
      handleRequestAgentCallbackModal('SUPPORT', REQUEST_SUPPORT_CALLBACK_PATH)
    }
  }, [dispatch, search, showSupportCallbacksModal, showSalesCallbacksModal, showModal, handleRequestAgentCallbackModal])

  useEffect(() => {
    if (termsAndConditionsAcknowledgedCartId) {
      showModal(<TermsAndConditionsAcknowledgedModal cartId={termsAndConditionsAcknowledgedCartId} />)

      const nextSearch = removeSearchParamValue(search, TERMS_AND_CONDITIONS_ACKNOWLEDGED_PATH, 'true')
      dispatch(replace({ search: nextSearch }))
    }
  }, [termsAndConditionsAcknowledgedCartId, showModal, dispatch, search])

  useEffect(() => {
    if (!ordersFetched) {
      dispatch(fetchOrders({ status: 'upcoming' }))
    }
    // eslint-disable-next-line
  }, [])

  const upsellOrder = useMemo(() => {
    return sortOrdersByDeparture(upcomingOrders)
      .find(upsellEnabledForOrder)
  }, [upcomingOrders])

  const showUpsellSection = ordersFetched &&
    upsellTilesFeatureToggle &&
    !!upsellOrder

  const {
    onTourListEvent,
    onCruiseListEvent,
    onHotelListEvent,
    onBestValueHotelListEvent,
    onUltraLuxListEvent,
    onHeroListEvent,
    onSearchEvent,
    onTrendingDestinationsListEvent,
    onExperienceListEvent,
    onTPFMListEvent,
    onTripOfferListEvent,
    onFlightListEvent,
    onLuxPlusListEvent,
  } = useHomepageAnalytics()

  /**
   * @see https://aussiecommerce.atlassian.net/browse/REC-3528
   *
   * Variants:
   * - `off` / `control`: Displays the default hero carousel and TPFM carousel.
   * - `on`: Replaces the hero carousel with the alternatives carousel, and the TPFM carousel shows the hero offers.
   * - `on_curated_first_offer`: Same as `on`, but the first hero offer remains in the hero carousel.
   */
  // --------- START: LERE Personalised Hero Carousel (AB TEST) ----------
  const heroOffers = useOfferList({
    ...getHomeOfferListParams({
      region: currentRegionCode,
      subregion: geoStateName,
    }),
  })
  const alternativesOffers = useOfferList(getAlternativesOfferListParams(), { noFetch: true })
  const canTrigger = useAppSelector(getCanTriggerLerePersonalisedHeroCarousel)
  const experimentVariation: 'on' | 'on_curated_first_offer' | 'control' | undefined = useOptimizelyExperiment(OptimizelyExperiments.lerePersonalisedHeroCarousel, canTrigger)
  const abcTestOverrides = useMemo(() => {
    let heroCarouselOfferList: App.OfferList | undefined
    let heroCarouselTitle: React.ReactNode | undefined
    let tpfmCarouselOfferList: App.OfferList | undefined
    let tpfmCarouselTitle: React.ReactNode | undefined
    let hideHeroCarouselFilters = false
    let hideTpfmCarouselEditPreferences = false

    // Force loading state for both carousels until BOTH offerLists have finished fetching
    if (alternativesOffers.fetching || heroOffers.fetching) {
      const placeholderOfferList: App.OfferList = { offerIds: [], fetching: true, key: 'placeholder-offer-list' }
      heroCarouselOfferList = placeholderOfferList
      tpfmCarouselOfferList = placeholderOfferList
    }

    if (canTrigger) {
      switch (experimentVariation) {
        case 'on':
          heroCarouselOfferList = alternativesOffers
          heroCarouselTitle = <>Top picks for <i>you</i></>
          tpfmCarouselOfferList = heroOffers
          tpfmCarouselTitle = <>Today's top <i>exclusive</i> offers</>
          hideHeroCarouselFilters = true
          hideTpfmCarouselEditPreferences = true
          break
        case 'on_curated_first_offer':
          const firstCuratedOffer = heroOffers.offerIds[0]
          heroCarouselOfferList = { ...alternativesOffers, offerIds: unique([firstCuratedOffer, ...alternativesOffers.offerIds]) }
          heroCarouselTitle = <>Top picks for <i>you</i></>
          tpfmCarouselOfferList = { ...heroOffers, offerIds: without(heroOffers.offerIds, firstCuratedOffer) }
          tpfmCarouselTitle = <>Today's top <i>exclusive</i> offers</>
          hideHeroCarouselFilters = true
          hideTpfmCarouselEditPreferences = true
          break
      }
    }

    return {
      heroCarouselOfferList,
      heroCarouselTitle,
      tpfmCarouselOfferList,
      tpfmCarouselTitle,
      hideHeroCarouselFilters,
      hideTpfmCarouselEditPreferences,
    }
  }, [experimentVariation, heroOffers, alternativesOffers, canTrigger])
  // --------- END: LERE Personalised Hero Carousel (AB TEST) ----------

  return (
    <AnalyticsPageContext.Provider value={AnalyticsPage.homepage}>
      <Pane>
        <ErrorBoundary fallback={null}>
          <PromptCardSelector tripReengagement carHire />
        </ErrorBoundary>
        <OfferListEventsProvider onListEvent={onHeroListEvent}>
          <HeroOfferCarouselSection
            overrideTitle={abcTestOverrides.heroCarouselTitle}
            overrideOfferList={abcTestOverrides.heroCarouselOfferList}
            hideFilters={abcTestOverrides.hideHeroCarouselFilters}
          />
        </OfferListEventsProvider>
        <GlobalSearchTrackingProvider onEvent={onSearchEvent}>
          <Search />
        </GlobalSearchTrackingProvider>
        {showUpsellSection &&
          <HomepageSection>
            <Group direction="vertical" gap={32}>
              <OfferDetailUpsellSection order={upsellOrder} />
              <CompactBookingDetailsUpsellSection order={upsellOrder} />
            </Group>
          </HomepageSection>
        }

        {showEuropeInspirationBanners && <EuropeInspirationBannerSection />}
        {showFrontPageUSP && <HomepageSection>
          <VerticalSpacer gap={40}>
            <FrontPageUSPComponent />
            <Divider kind="primary" />
          </VerticalSpacer>
        </HomepageSection>}
        {isLoggedIn && !showUpsellSection && config.TRIP_PLANNER_ENABLE_HOMEPAGE_MODULE && (
          <OfferListEventsProvider onListEvent={onTripOfferListEvent}>
            <Trip />
          </OfferListEventsProvider>
        )}
        {isLuxPlusEnabled && canRedeemLuxPlusBenefits && <OfferListEventsProvider onListEvent={onLuxPlusListEvent}>
          <LuxPlusOfferCarousel />
        </OfferListEventsProvider>}
        <OfferListEventsProvider onListEvent={onTrendingDestinationsListEvent}>
          <TrendingDestinations />
        </OfferListEventsProvider>
        {showFlightDeals && <OfferListEventsProvider onListEvent={onFlightListEvent}>
          <InView rootMargin="100px 0px 0px 0px" triggerOnce>
            <HomepageFlightDeals />
          </InView>
        </OfferListEventsProvider>}
        {!isLoggedIn && <LayoutContainer>
          <VerticalSpacer gap={40}>
            <Divider kind="primary" />
            <TrustIndicatorSection />
            <Divider kind="primary" />
          </VerticalSpacer>
        </LayoutContainer>}
        <RecentSearches />
        <OfferListEventsProvider onListEvent={onTPFMListEvent}>
          <TopPicksForMeCarousel
            onHotelListEvent={onHotelListEvent}
            overrideTitle={abcTestOverrides.tpfmCarouselTitle}
            overrideOfferList={abcTestOverrides.tpfmCarouselOfferList}
            hideEditPreferences={abcTestOverrides.hideTpfmCarouselEditPreferences}
          />
        </OfferListEventsProvider>
        {isLuxPlusEnabled && !canRedeemLuxPlusBenefits && <OfferListEventsProvider onListEvent={onLuxPlusListEvent}>
          <LuxPlusOfferCarousel />
        </OfferListEventsProvider>}
        <OfferListEventsProvider onListEvent={onTourListEvent}>
          <TourOfferCarousel />
        </OfferListEventsProvider>
        <OfferListEventsProvider onListEvent={onBestValueHotelListEvent}>
          <HotelOfferCarousel sortBy="value" />
        </OfferListEventsProvider>
        {isStandaloneCruiseEnabled && (
          <OfferListEventsProvider onListEvent={onCruiseListEvent}>
            <CruiseOfferCarousel />
          </OfferListEventsProvider>
        )}
        <MarketingCarousel />
        {isLoggedIn && <OfferListEventsProvider onListEvent={onHotelListEvent}>
          <HotelOfferCarousel />
        </OfferListEventsProvider>}
        {showSupportContainer && <LayoutContainer>
          <SupportTile />
        </LayoutContainer>}
        {config.ULTRALUX_ENABLED && (
          <OfferListEventsProvider onListEvent={onUltraLuxListEvent}>
            <UltraLuxOfferCarousel />
          </OfferListEventsProvider>
        )}
        <OfferListEventsProvider onListEvent={onExperienceListEvent}>
          <SmartExperienceOfferCarousel />
        </OfferListEventsProvider>
        <ValuePropositionBanner />
        <BlogCarousel />
        <BrowseOfferTypes />
      </Pane>
    </AnalyticsPageContext.Provider>
  )
}

function mapStateToProps(state: App.State): MappedProps {
  return {
    isLoggedIn: selectLoggedIn(state),
    isStandaloneCruiseEnabled: isStandaloneCruiseEnabled(state),
    isLuxPlusEnabled: isLuxPlusEnabled(state),
    canRedeemLuxPlusBenefits: checkCanRedeemLuxPlusBenefits(state),
  }
}

export default connect(mapStateToProps)(HomePage)
