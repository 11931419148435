import React, { useEffect } from 'react'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import BodyText from 'components/Luxkit/Typography/BodyText'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import { updateTermsAndConditions } from 'api/cart'

interface Props {
  cartId: string
}

function TermsAndConditionsAcknowledgedModal({ cartId }: Props) {
  useEffect(() => {
    updateTermsAndConditions(cartId, {
      accepted: true,
      acceptedAt: new Date().toISOString(),
    })
  }, [cartId])

  return (
    <ModalBase>
      <ModalHeader title="Thank you for reviewing the terms and conditions" />
      <ModalBody>
        <ModalContent>
          <BodyText variant="medium">
            Your acknowledgement has been recorded.
          </BodyText>
        </ModalContent>
      </ModalBody>
      <ModalFooter
        primaryActionProps={{
          children: 'Close',
        }}
      />
    </ModalBase>
  )
}

export default TermsAndConditionsAcknowledgedModal
