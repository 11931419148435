import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import React, { useEffect } from 'react'
import BlogCard from './BlogCard'
import TextButton from 'components/Luxkit/Button/TextButton'
import BodyText from 'components/Luxkit/Typography/BodyText'
import CardCarousel from 'components/Luxkit/Carousel/CardCarousel'
import LayoutContainer from 'components/Common/LayoutContainer/LayoutContainer'
import { rem } from 'polished'
import styled from 'styled-components'
import { useAppDispatch } from 'hooks/reduxHooks'
import { fetchDreamSiteBlogPosts } from 'actions/ContentActions'
import { connect } from 'react-redux'

const BlogCarouselContainer = styled(LayoutContainer)`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};
`

const DREAM_SITE_HOMEPAGE_LOCATION = 'le-home-page'

interface Props {
  blogPosts?: Array<App.DreamSiteBlogPost>;
}

function BlogCarousel(props: Props) {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchDreamSiteBlogPosts(DREAM_SITE_HOMEPAGE_LOCATION, 3))
    // eslint-disable-next-line
  }, [])

  const { blogPosts } = props

  return (<BlogCarouselContainer>
    <Group direction="vertical" gap={8}>
      <Group
        direction="horizontal"
        horizontalAlign="space-between"
        gap={16}
      >
        <Heading variant="heading2">
          Be <i>inspired</i> for your next adventure
        </Heading>
        <TextButton kind="tertiary" size="medium" href="https://luxuryescapes.com/inspiration/" target="_blank">
          View all
        </TextButton>
      </Group>
      <BodyText variant="large">
        Dream with our collection of articles and local travel trips
      </BodyText>
      <CardCarousel pageSize={3}>
        {blogPosts?.map((blogPost) => (
          <BlogCard
            key={blogPost.id}
            image={{
              url: blogPost.image.url,
            }}
            title={blogPost.title}
            description={blogPost.shortDescription}
            url={blogPost.url}
          />
        ))}
      </CardCarousel>
    </Group>
  </BlogCarouselContainer>
  )
}

function mapStateToProps(state: App.State) {
  return {
    blogPosts: state.content.dreamSiteBlogPosts[DREAM_SITE_HOMEPAGE_LOCATION],
  }
}

export default connect(mapStateToProps)(BlogCarousel)
