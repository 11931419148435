import React from 'react'

import HomePageContent from './HomePageContent'

function HomePage() {
  // Extra layer of home page present in case we need another home page take over like best of 2024
  return <HomePageContent />
}

export default HomePage
